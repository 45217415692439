
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '../Type/Type.module.scss';

$color-list: (
  'color-green': $color-highlight-green,
  'color-yellow': $color-highlight-yellow,
  'color-orange': $color-highlight-orange,
  'color-pink': $color-highlight-pink,
  'color-red': $color-highlight-red,
  'color-teal': $color-primary-teal,
  'color-black': $color-primary-black,
  'color-white': $color-primary-white
);

@each $key, $value in $color-list {
  .#{$key} {
    color: $value;
  }
}

.Prose {
  // Unset common styles left in place by WYSIWYG editor
  $prose-overrides: 'box-sizing', 'font-family', 'font-size', 'font-style',
    'font-weight', 'line-height', 'margin', 'padding', 'text-decoration',
    'white-space';

  @each $property in $prose-overrides {
    [style*='#{$property}'] {
      #{$property}: inherit !important;
    }
  }

  @extend .base-large;

  color: $color-primary-grey;

  &.isDark {
    color: $color-common-white;
  }

  > * + * {
    margin-top: spacing(5);
  }

  h1 {
    @extend .display2;

    color: $color-primary-teal;
  }

  h2 {
    @extend .title;

    color: $color-primary-teal;
    font-weight: normal;

    @include mq(0, $breakpoint-tablet - 1) {
      font-size: spacing(2.25); // 18
      line-height: spacing(3); // 24
    }
  }

  h3 {
    @extend .display1;

    color: $color-primary-teal;
  }

  em {
    font-style: italic;
  }

  li {
    list-style-position: outside;
    margin-left: spacing(3);
    margin-bottom: spacing(4);
  }

  ul {
    li {
      list-style-type: disc;

      li {
        list-style-type: circle;
      }
    }
  }

  ol {
    li {
      list-style-type: decimal;

      li {
        list-style-type: lower-roman;
      }
    }
  }

  a {
    color: $color-primary-teal;
    text-decoration: underline;
    transition: color $duration-standard $easing-standard;

    &:hover,
    &:focus,
    &:active {
      color: $color-primary-grey;
      transition: $duration-short $easing-standard;
    }
    &[name] {
      text-decoration: none;
      &:hover,
      &:focus,
      &:active {
        color: unset;
      }
    }
  }

  hr {
    height: 0;
    opacity: 0.5;
    border: 0;
    border-bottom: 1px solid currentColor;
    margin-bottom: -1px;
  }

  // Last in the group for specificity reasons
  > *:first-child {
    margin-top: 0;
  }

  dt,
  dd {
    margin-bottom: spacing(5);

    &:last-child {
      margin-bottom: 0;
    }
  }

  dt {
    font-weight: bold;
  }
}

.inverse {
  color: $color-primary-white;

  p {
    color: $color-primary-white;

    @media print {
      color: $color-primary-black !important;
    }
  }

  a {
    color: $color-primary-teal;
    &:hover,
    &:focus,
    &:active {
      color: $color-primary-white;
    }
  }
}
